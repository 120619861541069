<template>
  <div class="wrap">
    <div class="blue-title">
      <div class="img-wrap">
        <img src="../assets/img/qwjklogo.png" alt="" />
      </div>
      <div class="info-wrap">
        全网集客 · 让您不再缺客户
      </div>
      <div class="message">
        <span>加入团队</span>
      </div>
    </div>
    <div class="login-phone">
      <input
        type="number"
        placeholder="手机号"
        v-model="userPhone"
        maxlength="11"
      />
    </div>
    <div class="login-password">
      <input type="password" placeholder="密码" v-model="userPassword" />
    </div>
    <div class="button">
      <button @click="logOn()">加入团队</button>
    </div>
    <div class="register" @click="goToregister()">
      注册
    </div>
  </div>
</template>

<script>
import { getVerificode } from "../util/crypto";
export default {
  name: "home",
  data() {
    return {
      userPhone: "",
      userPassword: "",
      userDevice: null,
      //用户设备
      ambient: null,
      // 用户环境
      // displayFlag: true,
      //当用户是安卓并处于微信环境，显示提示框
      type: null,
      referrer: null,
      func: null,
      agree: false,
      // 勾选决定值
      isActive: false,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    check() {
      this.isActive = !this.isActive;
    },
    goToregister() {
      // this.$router.push({
      //   path: "/register/",
      //   query: {
      //     type: this.type,
      //     referrer: this.referrer,
      //     userPhone: this.userPhone,
      //   },
      // });
      this.$router.back();
    },
    seePolicy() {
      this.$router.push("/policy/");
    },
    logOn() {
      if (this.userPhone == "" || this.userPassword == "") {
        this.$message({
          message: "请输入用户名或密码",
          type: "error",
          offset: "300",
        });
      } else {
        let that = this;
        this.$http({
          method: "post",
          url: "/api/login/bind",
          data: {
            app_type: "qwjk",
            verificode: getVerificode(),
            mobile: this.userPhone,
            password: this.userPassword,
            type: this.type,
            referrer: this.referrer,
          },
        }).then((res) => {
          let data = res.data;
          console.log(data);
          if (data.code != 0) {
            this.$message({
              message: data.msg,
              type: "error",
              offset: "300",
            });
          } else if (data.code == 0) {
            this.$router.push("/success/");
          }
        });
      }
    },
  },
  created() {
    this.type = this.$route.query.type;
    this.referrer = this.$route.query.referrer;
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 750px;
  height: 100vh;
  overflow: hidden;

  .blue-title {
    position: relative;
    padding-top: 56px;
    width: 750px;
    height: 400px;
    background: #3e62f1;
    .img-wrap {
      display: flex;
      justify-content: center;
      img {
        height: 181px;
      }
    }
    .info-wrap {
      padding-top: 29px;
      display: flex;
      justify-content: center;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #f7f7f9;
      opacity: 0.8;
    }
    .message {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      bottom: -5px;
      left: 30px;
      width: 690px;
      height: 76px;
      background: #ffffff;
      border-radius: 4px;

      span {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #343538;
      }
    }
  }
  .login-phone {
    width: 625px;
    height: 63px;
    // background-color: rgb(89, 139, 231);
    margin-top: 89px;
    margin-left: 62px;
    border-bottom: 1px solid #dadee3;
    input {
      height: 50px;
      width: 625px;
      border: 0;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      // color: #B9B9BE;
    }
    input:focus {
      outline: none;
    }
  }
  .login-password {
    width: 625px;
    height: 63px;
    // background-color: rgb(89, 139, 231);
    margin-top: 56px;
    margin-left: 62px;
    border-bottom: 1px solid #dadee3;
    input {
      height: 50px;
      width: 625px;
      border: 0;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
    }
    input:focus {
      outline: none;
    }
  }
  .button {
    margin: 0 auto;
    margin-top: 83px;
    width: 622px;
    height: 90px;
    background-color: rgb(108, 139, 197);
    border-radius: 8px;
    button {
      width: 622px;
      height: 90px;
      border: 0;
      background: #3e62f1;
      border-radius: 8px;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .register {
    margin-top: 41px;
    margin-left: 64px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #3e62f1;
  }
}
</style>
